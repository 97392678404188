import * as THREE from "three";
import "../src/styles.css";
import gsap from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as dat from "dat.gui";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
// const cubeTextureLoader = new THREE.CubeTextureLoader()
// const doorColorTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/i930-color.jpg')
// const doorAlphaTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/Yw9Y-alpha.jpg')
// const doorAmbientOcclusionTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/nrrE-ambientOcclusion.jpg')
// const doorHeightTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/8JWy-height.jpg')
// const doorNormalTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/tN6K-normal.jpg')
// const doorMetalnessTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/voit-metalness.jpg')
// const doorRoughnessTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/o96z-roughness.jpg')
const matcapTexture = textureLoader.load('/matcaps/8.png')
// const gradientTexture = textureLoader.load('https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/EdrO-3.jpg')
// gradientTexture.magFilter = THREE.NearestFilter
// gradientTexture.generateMipmaps = false

/* const environmentMapTexture = cubeTextureLoader.load([
  '../asset/environmentMaps/0/px.jpg',
  'https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/V_c7-nx.jpg',
  'https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/cyL5-py.jpg',
  'https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/0gmh-ny.jpg',
  'https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/sOuU-pz.jpg',
  'https://uploads.codesandbox.io/uploads/user/f5c693a3-a5dc-4c91-8936-6220c882e924/_JRp-nz.jpg',
]); */

/**
 * Font Loader
 */
const fontLoader = new FontLoader()

fontLoader.load(
  '/fonts/helvetiker_regular.typeface.json',
  (font)=>{
    const textGeometry = new TextGeometry(
      'This IS SPARTA!',{
        font: font,
        size: .5,
        height: .1,
        curveSegments: 24,
        bevelEnabled: true,
        bevelThickness: 0.02,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 4
      }
    )
    // textGeometry.computeBoundingBox()
    // textGeometry.translate(
    //   - (textGeometry.boundingBox.max.x - 0.02) / 2,
    //   - (textGeometry.boundingBox.max.y - 0.02) / 2,
    //   - (textGeometry.boundingBox.max.z - 0.02) / 2,
    // )
    textGeometry.center()

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
    const text = new THREE.Mesh(textGeometry, material)
    scene.add(text)

    console.time('donuts')
    
    const donutGeometry = new THREE.TorusGeometry(.3, .15, 20, 45)

    for(let i = 0; i < 300; i++){
      const donut = new THREE.Mesh(donutGeometry, material)

      donut.position.x = (Math.random() - .5) * 40
      donut.position.y = (Math.random() - .5 ) * 20
      donut.position.z = (Math.random() - .5 ) * 20

      donut.rotation.x = Math.random() * Math.PI
      donut.rotation.y = Math.random() * Math.PI

      const scale = Math.random()
      donut.scale.set(scale, scale, scale)

      scene.add(donut);
    }

    console.timeEnd('donuts')
  }
)

// Debugging with dat.gui
const gui = new dat.GUI();

/**
 * Base Scene, Camera, Renderer, etc.
 */
const sizes={
    width: window.innerWidth,
    height: window.innerHeight
}
const scene = new THREE.Scene()
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
const canvas = document.querySelector('.webgl')
const renderer = new THREE.WebGLRenderer({canvas: canvas})
renderer.setSize(sizes.width, sizes.height)
camera.position.z = 2;

/**
 * Materials
 */
/* const material = new THREE.MeshBasicMaterial()
material.map = doorColorTexture
material.side = THREE.DoubleSide

const material = new THREE.MeshNormalMaterial()
material.flatShading = true

const material = new THREE.MeshMatcapMaterial()
material.matcap = matcapTexture

const material = new THREE.MeshDepthMaterial()

const material = new THREE.MeshLambertMaterial()

const material = new THREE.MeshPhongMaterial()
material.shininess = 100
material.specular = new THREE.Color(0x118eff)

const material = new THREE.MeshToonMaterial()
material.gradientMap = gradientTexture

const material = new THREE.MeshStandardMaterial()
material.map = doorColorTexture
material.aoMap = doorAmbientOcclusionTexture
material.displacementMap = doorHeightTexture
material.displacementScale = 0.1
material.metalnessMap = doorMetalnessTexture
material.roughnessMap = doorRoughnessTexture
material.normalMap = doorNormalTexture
material.normalScale.set(0.5, 0.5)
material.transparent = true
material.alphaMap = doorAlphaTexture */

/* const material = new THREE.MeshStandardMaterial()
material.metalness = 0.7
material.roughness = 0.2
material.envMap = environmentMapTexture

gui.add(material, 'roughness', 0, 1, 0.01)
gui.add(material, 'metalness', 0, 1, 0.01)
gui.add(material, 'aoMapIntensity', 0, 1, 0.01)
gui.add(material, 'displacementScale', 0, 1, 0.01) */


/**
 * Objects
 */
/*const sphere = new THREE.Mesh(
  new THREE.SphereGeometry(0.5, 64, 64),
  material
)
sphere.position.x = -2

const plane = new THREE.Mesh(
  new THREE.PlaneGeometry(1, 1, 100, 100),
  material
)
plane.material.side = THREE.DoubleSide

const torus = new THREE.Mesh(
  new THREE.TorusGeometry(.3, .2 , 64, 128),
  material
)
torus.position.x = 2
scene.add(sphere, plane, torus); */


/**
 * Lights
 */
/* const ambientLight = new THREE.AmbientLight(0xffffff, .5)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, .5)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight) */

/**
 * OrbitControls
 */
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true

//Clock
const clock = new THREE.Clock()

// Render loop
const tick = () => {
  const elapsedTime = clock.getElapsedTime()

  //Update objects
  /* sphere.rotation.y = 0.1 * elapsedTime
  plane.rotation.y = 0.1 * elapsedTime
  torus.rotation.y = 0.1 * elapsedTime

  sphere.rotation.x = 0.15 * elapsedTime
  plane.rotation.x = 0.15 * elapsedTime
  torus.rotation.x = 0.15 * elapsedTime */

  //Update Controls
  controls.update();


  requestAnimationFrame(tick);
  renderer.render(scene, camera);
};
tick();